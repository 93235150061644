import WPhoto_GlowLinePhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/GlowLinePhoto.skin';
import WPhoto_GlowLinePhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_GlowLinePhoto = {
  component: WPhoto_GlowLinePhotoComponent,
  controller: WPhoto_GlowLinePhotoController
};


export const components = {
  ['WPhoto_GlowLinePhoto']: WPhoto_GlowLinePhoto
};

